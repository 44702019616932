import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {SharedApiService} from '../../shared/services/api.service';
import {NewsItems} from "../models/news-item.model ";

@Injectable()
export class NewsItemsResolver implements Resolve<NewsItems> {
    constructor(private apiService: SharedApiService) {
    }

    public resolve(): Observable<NewsItems> {
        return this.apiService.getNewsItems();
    }
}
